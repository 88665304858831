.formContainer{
    background-color: #dbdbdb;
    border-radius: 8px;
    max-width: 60% !important;
}

.paymentOptionButton{
    background-color: #dbdbdb;
    border: none;
    border-radius: 5px;
}

.paymentOptionButton:hover {background-color: #cecece}


.footer{
    background-color: #0067c8;
    color:white;
    font-family: Georgia;
    margin-top:auto;
}
.footer-top{
    padding-left: 20px;

}
.logos {
    width: 30%;
    object-fit: contain;
    min-width: 150px;
    max-height:150px;
}
.links{
    color:white;
}

.copyright{
    padding-top: 25px;
    padding-bottom:50px;
    padding-left: 20px;
    font-size:small;

}

.LoadMoreButtonContainer{
    position: relative;
    top: -10px;
    left: 45%;
}

.orderSearchInput{
    width: 25%;
}

.orderSearchButton{
    width: 40px;
    height: 30px;
    margin: 0;
    position: relative;
    top: 0px;
    left: -1px;
    border-width: 1px;
}

#searchIcon{
    position: relative;
    top: -2px;
}

#daterangetext{
    width: 208px;
    height: 30px;
    display: inline-block;
    margin: 0;
    position: relative;
    top: 2px;
    left: 15px;
}

.plots{
    display: flex;
    flex-direction: row;
}

#monthplot{
    bottom: 1000px;
}

#addAdminCard{
    margin-top: -10px;
    margin-left: 17px;
}

.addAdminInput{
    width: 75%;
    margin-bottom: 5px;
}

.addAdminButton{
    width: 25%;
    margin-left: 50%;
}

.addAdminButton:hover{
    background: "#d6d6d6";
}

.addAdminForm{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.homeTitleForm{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.homeTitleButton{
    width: 25%;
    margin-top: 5px;
}

.homeDescForm{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.homeDescButton{
    width: 25%;
    margin-top: 5px;
}

.rotaryContactForm{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.rotaryContactButton{
    width: 25%;
    margin-top: 5px;
}

.campOacContactForm{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.campOacContactButton{
    width: 25%;
    margin-top: 5px;
}

.woodDescForm{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.woodDescButton{
    width: 25%;
    margin-top: 5px;
}

.adminRemoveButton{
    border-width: 1px;
}

.addLocationForm{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.addLocationInput{
    width: 65%;
    margin-bottom: 5px;
}

.addLocationButton{
    width: 25%;
}

#addLocationCard{
    margin-top: -10px;
    margin-left: 17px;
}

#map{
    width: 115%;
    height: 400px;
    background-color: grey;
    margin-left: -7.5%;
    margin-top: 0px;
}

#addressContinueButton{
    margin-top: -2px;
}

.scrollLink{
    text-decoration: none;
    margin-top: 8px;
    color: #ffffff;
    opacity: 0.55;
}

.scrollLink:hover{
    color: #ffffff;
    opacity: 0.75;
}

#backToTop{
    position: sticky;
    margin-left: 45%;
    top: 97%;
    z-index: 2;
}

#woodDesc{
    line-height: 25px;
}

#woodDescTopText{
    margin-top: 3px;
}

#woodDescBottomText{
    margin-top: 13px;
}

#e-Transfer-radio{
    margin-left: 16px;
}

#Square-radio{
    margin-left: 27px;
}

#Cash-radio{
    margin-left: 23px;
}

.discountInput{
    width: 10%;
    text-align: right;
}

#discountForm{
    font-weight: normal;
}

.plusSign{
    margin-bottom: 3px;
}

.inventoryXs{
    margin-bottom: 5px;
    margin-right: 20px;
}

.inventoryXbtns{
    width: 26px;
    height: 25.5px;
}