.home{
    background-color:white;
 }
.writeup{
   color:#17458f;
   font-family: Georgia ;
   text-align: center;

}
.spacer{
    height: 2px;
    width: 100%;
    background-color:#17458f;
}
.data{
    color:white;
   font-family: Georgia ;
}
.picsContainer{
    background-color:#17458f;
}
.carousel {
    width:70%;
    max-height:60%;
  }
.fwood{
    width: 100%;
    height:425px;
    background-image: url("../../public/Slide1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
