.login{
    text-align: center;
    max-width: 25%;
    align-content: center;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    
}
.orders{
    padding-top: 20px;
    padding-bottom: 20px;
    
}